import { ButtonContent } from "../../global/boton/ButtonContent";

function HeroSection({ bgImg, bgImg1, bgImg2, bgImg3, title, texts }) {

  const BgImages = [
    bgImg,
    bgImg1,
    bgImg2,
    bgImg3,
  ]

  return (
    <section className={'svgBoxRectanguloBottonRigth'}>

      <div className="pic-wrapper svgHerosection2  w-full h-screen" >
        {
          BgImages.map((item, index) => {
            return (
              <figure
                key={index}
                className={`pic-${index + 1}`}
                style={{ background: `url("${item}")` }}
              ></figure>
            )
          })
        }
        <div className="z-10 relative w-4/5 mx-auto h-full flex flex-col justify-end items-center pb-28">
          <div className="w-full flex flex-col justify-center items-center">
            <h1 className="text-white text-center text-[29px] mb-6 md:text-[35px] lg:text-[50px]">
              {title}
            </h1>
            <p className="text-white text-center text-[14px] lg:text-[16px] leading-8">
              {texts}
            </p>
          </div>

          <div className="w-full flex justify-center py-5">
            <ButtonContent btnStyle={'three'} />
          </div>

          <div>
            <img className="w-[300px]" src="https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/archivos_varios%2Fsello22%20google.png?alt=media&token=fd79370f-b2ac-414f-a4ce-d538c6ad19d5" alt="google"/>
          </div>
        </div>
      </div>


    </section>

  )
}

export default HeroSection;
